@font-face {
    font-family: 'Sofia Pro';
    src: url('../../Fonts/sofia-pro-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
} 

  .center-content-cnt, .content-img-cnt {
    padding: 160px; /* Ajusta el padding según tus necesidades */
    box-sizing: border-box;
}
  
  .content-cnt {
    padding: 10px; /* Espacio interno del contenido */
}

.center-content-cnt {
    text-align: center;
    height: 70vh;
    margin-top: 350px;
  }

  /* --------------------- Cards Stuff-------------------- */
  .card-container-contact {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
    flex-wrap: wrap; /* Agrega envoltura de línea para que las tarjetas se apilen */
  }
  
  .card-contact {
    width: 400px;
    background-color: var(--darkgray-dark);
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box; /* Evita que el relleno incremente el tamaño total */
  }

  /* Media Query para pantallas más pequeñas */
@media (max-width: 768px) {
  .card-contact {
    width: 90%; /* Reduce el ancho en pantallas pequeñas */
    margin: 0 auto 20px; /* Centra horizontalmente y agrega margen inferior */
  }
}
  .card-text-contact {
    margin-top: 10px;
  }

  .card-icon-contact {    
    color: #D85D00;
    font-size: 40px;  
    margin-bottom: 20px; 
  }

  .first-text-card{
    font-family: 'Sofia Pro', sans-serif;
    font-size: 18px;
    color: var(--lightgray-light);
    margin-bottom: 30px;
    font-weight: bold;

  }

  .second-text-card{
    font-family: 'Sofia Pro', sans-serif;
    font-size: 18px;
    color: var(--lightgray-lightest);
  }

 /*--------------------------- Form styles ----------------------------*/
.contact-form {
  max-width: 50%; /* Utiliza porcentaje para adaptarse al ancho de la pantalla */
  margin: 50px auto; /* Centra vertical y horizontalmente */
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 3vw;
  padding-right: 3vw;
  border-radius: 10px;
  background: #323232;
}

  /* For screens smaller than 1200px */
  @media only screen and (max-width: 1200px) {
    .contact-form {
      max-width: 65%; /* Adjust as needed */
    }
  }

  /* For screens smaller than 800px */
  @media only screen and (max-width: 800px) {
    .contact-form {
      max-width: 80%; /* Adjust as needed */
    }
  }

  /* For screens smaller than 600px */
  @media only screen and (max-width: 600px) {
    .contact-form {
      max-width: 92%; /* Adjust as needed */
    }
  }

  /* For screens smaller than 400px */
  @media only screen and (max-width: 460px) {
    .contact-form {
      max-width: 100%; /* Adjust as needed */
    }
  }

.form-input {
  width: 100%; /* Ocupa todo el ancho del contenedor */
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid var(--darkgray-dark);
  border-radius: 10px;
  background-color: transparent;
  color: white;
}

.form-input::placeholder {
  color: var(--lightgray-lightest); 
}

.form-submit {
  font-family: 'Sofia Pro', sans-serif;
  background: rgb(237, 197, 113);
  color: white !important;
  background: var(--DO-IT, linear-gradient(123deg, #D85D00 25.28%, #FF8E01 81.79%));
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-family: 'Sofia Pro', sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  height: 50px;
  width: 100%; /* Ocupa todo el ancho del contenedor */
  margin: 0 auto;
}



/* HUBSPOT FORM STYLING */

/* HUBSPOT FORM STYLING */
.hubspot-form-container {
  margin: 50px auto; /* Align center with margins similar to contact-form */
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 3vw;
  padding-right: 3vw;
  border-radius: 10px;
  background: #FBE4BA;
  max-width: 50%;
}

.hubspot-form-container iframe {
  border: none;
  width: 100%;
  height: 500px; /* Adjust height as needed */
  border-radius: 10px; /* Match border radius */
}