@font-face {
    font-family: 'Sofia Pro';
    src: url('../../Fonts/sofia-pro-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }  
/* This is the change I made, but now with the real pull request */
:root {
  /* Yellow */
  --yellow-dark: #F7C975;
  --yellow-medium: #F9D798;
  --yellow-light: #FBE4BA;
  --yellow-lightest: #FDF2DD;

  /* Orange */
  --orange-dark: #E96120;
  --orange-medium: #EF8958;
  --orange-light: #F4B090;
  --orange-lightest: #FAD8C7;

  /* Dark Green */
  --darkgreen-dark: #3D5841;
  --darkgreen-medium: #6E8271;
  --darkgreen-light: #9EACA0;
  --darkgreen-lightest: #CFD5D0;

  /* Light Green */
  --lightgreen-dark: #9FB8AD;
  --lightgreen-medium: #B7CAC2;
  --lightgreen-light: #CFDCD6;
  --lightgreen-lightest: #E7EDEB;

  /* Dark Gray */
  --darkgray-dark: #3F403F;
  --darkgray-medium: #6F706F;
  --darkgray-light: #9FA09F;
  --darkgray-lightest: #CFCFCF;

  /* Light Gray */
  --lightgray-dark: #CED0CE;
  --lightgray-medium: #DADCDA;
  --lightgray-light: #E7E8E7;
  --lightgray-lightest: #F3F3F3;
}

.elipse-top-right {
  width: 501.6px;
  height: 501.6px;
  flex-shrink: 0;
  border-radius: 501.6px;
  background: #FF8E01;
  filter: blur(206.39999389648438px);
}

body {
    margin: 0;
    padding: 0;
    padding-top: 10vh;
    height: 100vh;
    background: linear-gradient(to right top, #d55f23, #d55f23, #050404, #050404, #050404, #050404, #050404, #050404, #050404, #050404, #050404, #050404, #050404, #050404, #050404, #d55f23, #d55f23);
    background-size: cover;
    background-attachment: fixed;
  }

  .page-wrapper {
    /* height: 90vh; */
    width: 100%;
    /* padding-top: 0vh; */
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
  }

  .center-fold {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
  }
  
  .section-padding {
    padding-top: 12vh;
    padding-bottom: 15vh;
    padding-left: 12vh;
    padding-right: 12vh;
  }

  /* SECTION PADDING (TOP & BOTTOM) */

  /* For screens smaller than 1200px */
  @media only screen and (max-width: 1200px) {
    .section-padding {
      padding-top: 10vh;
      padding-bottom: 12vh;
      padding-left: 10vh;
      padding-right: 10vh;
    }
  }

  /* For screens smaller than 800px */
  @media only screen and (max-width: 900px) {
    .section-padding {
      padding-top: 8vh;
      padding-bottom: 10vh;
      padding-left: 7vh;
      padding-right: 7vh;
    }
  }

  /* For screens smaller than 600px */
  @media only screen and (max-width: 650px) {
    .section-padding {
      padding-top: 6vh;
      padding-bottom: 8vh;
      padding-left: 5vh;
      padding-right: 5vh;
    }
  }

  /* For screens smaller than 400px */
  @media only screen and (max-width: 460px) {
    .section-padding {
      padding-top: 4vh;
      padding-bottom: 5vh;
      padding-left: 2vh;
      padding-right: 2vh;
    }
  }

  .logo-image-box {
    height: 20%;
    justify-self: top;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-image {
    width: auto;
    height: 100%;
    align-items: center;
  }

  .main-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: -1.2px;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: center;
    width: 100%;
    gap: 10px;
  }
  
  @media (min-width: 900px) { /* Change this breakpoint according to your design needs */
    .content-container {
      text-align: left;
      flex-direction: row; /* On wider screens, align them horizontally */
    }
  }

  .content-container-b {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 100%;
    gap: 1px;
  }
  
  @media (min-width: 900px) { /* Change this breakpoint according to your design needs */
    .content-container-b {
      flex-direction: row; /* On wider screens, align them horizontally */
    }
  }

  .text-box-b {
    padding-left: 2vw;
  }

  .text-container {
    width: 100%; /* Full width on mobile */
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    gap: 1vw;
    align-self: stretch;
  }

  .text-container-b {
    width: 100%; /* Full width on mobile */
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    gap: 1px;
    align-self: stretch;
    padding-left: 4vw;
  }

  .headeer-container {
    display: inline-block;
    text-align: center;
  }

  .list-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;  
  }

  .about-text-container {
    width: 50%;
    height: 100%;
    color: #B9B791;
    font-family: 'Sofia Pro';
    font-style: normal;
    font-weight: 400;
    text-align: left;
  }

  
  .about-check-container {
    display: flex;
    width:50%;
    padding-left: 6vw;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  
  /* For screens smaller than 800px */
  @media only screen and (max-width: 800px) {
    .about-check-container,
    .about-text-container {
      width: 100%;
    }
  }

  .gradient-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .separator {
    flex-shrink: 0;
    height: 10vh;
    margin: 40px 0;
  }

  .medium-separator {
    flex-shrink: 0;
    height: 3vh;
  }

  .small-separator {
    flex-shrink: 0;
    height: .25vh;
  }

  .tiny-separator {
    flex-shrink: 0;
    height: .10vh;
  }

  .cta1 {
    font-family: 'Sofia Pro';
    background: var(--orange-dark);
    color: #CED0CE  !important;
    background: var(--DO-IT, linear-gradient(123deg, #D85D00 25.28%, #FF8E01 81.79%));
    border-radius: 40px;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .cta1:hover {
    filter: brightness(1.1);
    color: #DADCDA !important;
  }

  /* For screens smaller than 1200px */
  @media only screen and (max-width: 1200px) {
    .cta1 {
      font-size: 1rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 800px */
  @media only screen and (max-width: 800px) {
    .cta1 {
      font-size: .9rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 600px */
  @media only screen and (max-width: 600px) {
    .cta1 {
      font-size: .8rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 460 */
  @media only screen and (max-width: 460px) {
    .cta1 {
      font-size: .7rem; /* Adjust as needed */
    }
  }

  .cta-bar {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-color {
    margin-right: 10px;
    color: white;
    font-size: 1.3rem;
    border-radius: 100%;
    padding: 8px;
    background: #e97420;
  }

  /* For screens smaller than 1200px */
  @media only screen and (max-width: 1200px) {
    .icon-color {
      font-size: 1rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 800px */
  @media only screen and (max-width: 800px) {
    .icon-color {
      font-size: .9rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 800px */
  @media only screen and (max-width: 600px) {
    .icon-color {
      font-size: .8rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 800px */
  @media only screen and (max-width: 460px) {
    .icon-color {
      font-size: .7rem; /* Adjust as needed */
    }
  }




  /* LARGE TEXT */

  .white-text-large {
    color: white;
    text-align: center;
    font-family: 'Sofia Pro';
    font-size: 5.75rem;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    text-transform: capitalize;
  }

  .gradient-text-large {
    font-family: 'Sofia Pro';
    font-size: 5.75rem;
    background: linear-gradient(123deg, #D85D00 25.28%, #FF8E01 81.79%);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
  }

  /* For screens smaller than 1200px */
  @media only screen and (max-width: 1200px) {
    .gradient-text-large,
    .white-text-large {
      font-size: 4rem;
    }
  }

  /* For screens smaller than 800px */
  @media only screen and (max-width: 800px) {
    .gradient-text-large,
    .white-text-large {
      font-size: 3.5rem;
    }
  }

  /* For screens smaller than 600px */
  @media only screen and (max-width: 600px) {
    .gradient-text-large,
    .white-text-large {
      font-size: 2.5rem;
    }
  }

  /* For screens smaller than 400px */
  @media only screen and (max-width: 460px) {
    .gradient-text-large,
    .white-text-large {
      font-size: 2rem;
    }
  }




  /* MEDIUM-LARGE TEXT */

  .white-text-medium-large {
    font-family: 'Sofia Pro';
    font-size: 4rem;
    color: white;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .gradient-text-medium-large {
    font-family: 'Sofia Pro';
    font-size: 4rem;
    background: linear-gradient(123deg, #D85D00 25.28%, #FF8E01 81.79%);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
  }

  /* For screens smaller than 1200px */
  @media only screen and (max-width: 1200px) {
    .gradient-text-medium-large,
    .white-text-medium-large {
      font-size: 3.25rem;
    }
  }

  /* For screens smaller than 800px */
  @media only screen and (max-width: 800px) {
    .gradient-text-medium-large,
    .white-text-medium-large {
      font-size: 2.5rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 600px */
  @media only screen and (max-width: 600px) {
    .gradient-text-medium-large,
    .white-text-medium-large {
      font-size: 2.1rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 400px */
  @media only screen and (max-width: 460px) {
    .gradient-text-medium-large,
    .white-text-medium-large {
      font-size: 1.75rem; /* Adjust as needed */
    }
  }




  /* MEDIUM TEXT */

  .white-text-medium {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 3rem;
    color: white;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .gradient-text-medium {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 3rem;
    background: linear-gradient(123deg, #D85D00 25.28%, #FF8E01 81.79%);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
  }

  /* For screens smaller than 1200px */
  @media only screen and (max-width: 1200px) {
    .gradient-text-medium,
    .white-text-medium {
      font-size: 2.25rem; /* Adjust as needed */
    }
  }
  
  /* For screens smaller than 800px */
  @media only screen and (max-width: 800px) {
    .gradient-text-medium,
    .white-text-medium {
      font-size: 1.75rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 600px */
  @media only screen and (max-width: 600px) {
    .gradient-text-medium,
    .white-text-medium {
      font-size: 1.5rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 400px */
  @media only screen and (max-width: 460px) {
    .gradient-text-medium,
    .white-text-medium {
      font-size: 1.25rem; /* Adjust as needed */
    }
  }


  /* SMALL-MEDIUM TEXT */

  .white-text-small-medium {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 1.7rem;
    color: white;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .gradient-text-small-medium {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 1.7rem;
    background: linear-gradient(123deg, #D85D00 25.28%, #FF8E01 81.79%);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
  }

  /* For screens smaller than 1200px */
  @media only screen and (max-width: 1200px) {
    .gradient-text-small-medium,
    .white-text-small-medium {
      font-size: 1.5rem; /* Adjust as needed */
    }
  }
  
  /* For screens smaller than 800px */
  @media only screen and (max-width: 800px) {
    .gradient-text-small-medium,
    .white-text-small-medium {
      font-size: 1.25rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 600px */
  @media only screen and (max-width: 600px) {
    .gradient-text-small-medium,
    .white-text-small-medium {
      font-size: 1.1rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 400px */
  @media only screen and (max-width: 460px) {
    .gradient-text-small-medium,
    .white-text-small-medium {
      font-size: .95rem; /* Adjust as needed */
    }
  }



  /* SMALL TEXT */

  .gradient-text-small {
    font-family: 'Sofia Pro';
    font-size: 1.75rem;
    background: linear-gradient(123deg, #D85D00 25.28%, #FF8E01 81.79%);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
  }

  .small-text {
    font-family: 'Sofia Pro';
    font-size: 1.3rem;
    color: #B9B791;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.336px;
  }

  .white-text-small {
    font-family: 'Sofia Pro';
    font-size: 1.3rem;
    color: white;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.336px;
  }

  /* For screens smaller than 1200px */
  @media only screen and (max-width: 1200px) {
    .small-text,
    .gradient-text-small {
      font-size: 1.25rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 800px */
  @media only screen and (max-width: 800px) {
    .small-text,
    .gradient-text-small {
      font-size: 1.1rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 600px */
  @media only screen and (max-width: 600px) {
    .small-text,
    .gradient-text-small {
      font-size: .95rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 400px */
  @media only screen and (max-width: 460px) {
    .small-text,
    .gradient-text-small {
      font-size: .85rem; /* Adjust as needed */
    }
  }