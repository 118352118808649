@font-face {
  font-family: 'Sofia Pro';
  src: url('../../Fonts/sofia-pro-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} 

  .left-container {
    display: flex;
    width: 50%;
    height: auto;
    align-items: center;
    justify-content: left;

    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    gap: 1vw;
    align-self: stretch;
  }

  .center-content {
    text-align: center;
  }

  .card-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
  }

  .card {
    width: auto;
    margin: 20px;
    border: none;
    background: none; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    /* background: #323232; */
    padding-top: 1vh;
    padding-bottom: 2vh;
    padding-left: 1vw;
    padding-right: 1vw;
    border-radius: 40px;
  }

  .card-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    /* margin-bottom: 20px; */
  }

  @media (min-width: 800px) { /* Change this breakpoint according to your design needs */
    .card-container {
      flex-direction: row; /* On wider screens, align them horizontally */
    }
  }
  
  .center-content-partner {
    text-align: center;
    height: 70vh;
    margin-top: 30px;
    display: flex;
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
    height: auto; /* Ajustar la altura al 100% de la altura de la ventana */
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Espacio entre los elementos */
  }
  
  .content {
    padding: 10px; /* Espacio interno del contenido */
  }


  .carousel-container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
 

  .gradient-text-about {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 20px;
    font-weight: bold;
    /*margin: 0;  Elimina el margen predeterminado del párrafo */
    margin-left: 10px;
    display: inline; /* Elimina el margen inferior del párrafo */
    background: linear-gradient(90deg, rgba(247,201,117,1) 0%, rgba(233,97,32,1) 50%, rgba(247,201,117,1) 100%);
    -webkit-background-clip: text;
    color: transparent;
  }

   .gradient-container-feedback {
    background-color:#111111; /* Color de fondo gris */
    border-radius: 5px; /* Esquinas redondeadas */
    width: 120px; /* Tamaño del contenedor */
    height: auto;
    margin-bottom: 30px;
  }

  .gradient-text-feedback {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 20px;
    font-weight: bold;    
    display: inline;   
    background: linear-gradient(90deg, rgba(247,201,117,1) 0%, rgba(233,97,32,1) 50%, rgba(247,201,117,1) 100%);
    -webkit-background-clip: text;
    color: transparent;
  }

  .white-text-about {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 3rem;
    color: white;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .small-text-about {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 20px;
    color: #b9b7b1;
  }

  .get-started-btn3 {
    font-family: 'Sofia Pro', sans-serif;
    background: rgb(237, 197, 113);
    color: white !important;
    background: linear-gradient(
      90deg,
      rgba(237, 197, 113, 1) 0%,
      rgba(213, 95, 35, 1) 50%,
      rgba(237, 197, 113, 1) 100%
    );
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-family: 'Sofia Pro', sans-serif;
    font-weight: 500;
    font-size: 40px;
    cursor: pointer;
    height: 40px;
    width: auto;
    margin: 0 auto;
  }
  
  .get-started-btn3:hover {
    filter: brightness(1.2);
    color: white !important;
  }

  .get-started-btn4 {
    font-family: 'Sofia Pro', sans-serif;
    background: rgb(237, 197, 113);
    color: white !important;
    background: linear-gradient(
      90deg,
      rgba(237, 197, 113, 1) 0%,
      rgba(213, 95, 35, 1) 50%,
      rgba(237, 197, 113, 1) 100%
    );
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-family: 'Sofia Pro', sans-serif;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    height: 50px;
    width: auto;
    margin: 0 auto;
    
  }
  
  .get-started-btn4:hover {
    filter: brightness(1.2);
    color: white !important;
  }

  .get-started-btn5 {
    font-family: 'Sofia Pro', sans-serif;
    background: rgb(237, 197, 113);
    color: white !important;
    background: linear-gradient(
      90deg,
      rgba(237, 197, 113, 1) 0%,
      rgba(213, 95, 35, 1) 50%,
      rgba(237, 197, 113, 1) 100%
    );
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-family: 'Sofia Pro', sans-serif;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
    height: 50px;
    width: auto;
    margin: 0 auto;
    
  }
  
  .get-started-btn5:hover {
    filter: brightness(1.2);
    color: white !important;
  }

  .custom-list {
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
    text-align: left;
  }
  
  .custom-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .icon {
    margin-right: 50px;
    color: #b9b7b1;
    font-size: 2vw;
    border-radius: 100%;
    padding: 8px;
    background: #111111;
  }
  
  .text {
    font-size: 25px;
    color: #b9b7b1;
  }

  .text-carousel {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 30px;
    color: #b9b7b1;
  }

  .name-carousel {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 20px;
    color: #ffffff;
  }

  .charge-carousel {  
    font-family: 'Sofia Pro', sans-serif;
    font-size: 17px;
    font-style: italic;
    color: #b9b7b1;
    margin: 0;
  }

  .belief-link-container {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }
  
  .text-carousel-title {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 20px;
    color: #b9b7b1;
  }

  .carousel-image {
    width: 50%;
    height: auto;
  }

  .white-text-partner {
    font-family: 'Sofia Pro', sans-serif;
    font-size: 2rem;
    color: white;
    margin-bottom: 10px;
    font-weight: bold;
    white-space: nowrap; 
  }
  
  .text-partner {
    font-size: 20px;
    color: #b9b7b1;
  }

  .white-text-partner:hover{
    color:rgba(213, 95, 35, 1);
  }

  .card-icon {
    margin-right: 10px;
    color: #D85D00;
    font-size: 5rem;
    border-radius: 100%;
    padding: 8px;
  }

  /* For screens smaller than 1200px */
  @media only screen and (max-width: 1200px) {
    .card-icon {
      font-size: 7rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 800px */
  @media only screen and (max-width: 800px) {
    .card-icon {
      font-size: 6rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 600px */
  @media only screen and (max-width: 600px) {
    .card-icon {
      font-size: 5rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 460px */
  @media only screen and (max-width: 460px) {
    .card-icon {
      font-size: 4rem; /* Adjust as needed */
    }
  }

  .gradient-text2 {
    font-family: 'Sofia Pro';
    font-size: 3rem; /* Tamaño grande */
    background: linear-gradient(90deg, rgba(237,197,113,1) 0%, rgba(213,95,35,1) 50%, rgba(237,197,113,1) 100%);
    -webkit-background-clip: text;
    color: transparent;
    margin-bottom: 10px; /* Espacio entre textos */
    font-weight: bold;
  }

  .white-text-belief {
    font-family: 'Sofia Pro';
    font-size: 3rem; /* Tamaño grande */
    color: white;
    margin-bottom: 10px; /* Espacio entre textos */
    font-weight: bold;
    white-space: nowrap; 
  }

  .white-text-bottom {
    font-family: 'Sofia Pro';
    font-size: 40px; /* Tamaño grande */
    color: white;
    margin-bottom: 10px; /* Espacio entre textos */
    font-weight: bold;
    white-space: nowrap; 
    margin-right: 100px; 
  }

  .mvv-text-container {
    padding-top: 2vh;
    text-align: left;
  }

  .mvv-expandable-text-container {
    width: 100%;
    display: flex;
    padding-top: 10vh;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    gap: 2vw;
    align-self: stretch;
    text-align: left;
    text-emphasis-color: white
  }

  .card-belief{
    width: 100%;
    /* padding-top: 1vh;
    padding-bottom: 1vh; */
    padding: 1vh 5vw;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    max-height: 80px; /* Altura original de la tarjeta */
    font-family: 'Sofia Pro', sans-serif;
    color: #B9B791;
    background: #323232;
  }

  .card-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0;
    padding-top: 1vh;
    padding-bottom: 1vh;
  }
  
  @media (min-width: 800px) {
    .card-belief {
      width: 50vw;
    }
  }

  .card-belief:hover{
    transform: translateY(-5px);
  }
  
  .card-belief.expanded {
    max-height: 600px;
  }

  .card-belief.expanded::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      /* background-color: #4E4B4B; */
  }

  .expanded-content {
      margin-top: 10px;
      /* background: #4E4B4B; */
      color: white;
  }

  @media (min-width: 800px) {
    .expanded-content {
      /* font-size: 18px; */
    }
  }
