/* navbar-styles.css */
@font-face {
    font-family: 'Sofia Pro';
    src: url('../../Fonts/sofia-pro-regular.ttf') format('truetype');
  }
  
  .navbar {
    /* background-color: #050404; */
    height: 10vh; /* Ajusta la altura como desees */
    width: 100vw;
    display: flex;
    padding-left: 3.5vw;
    padding-right: 3.5vw;
    padding-top: 3vh;
    padding-bottom: 3vh;
    align-items: center; /* Centrar verticalmente */
    position: fixed; /* Se necesita para posicionar el seudoelemento */
    top:0;
    left: 0;
    z-index: 1;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Agrega transiciones suaves */
    background-color: #050404;
  }

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: left;
   
    height: 100%;
    flex-grow: 1;
  }
    
  .navlink-container {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 100%;
    width: 40vw;
    /* justify-content: center; */
  }
  
  /* Navbar element styles */
  .nav .nav-item .nav-link {
    color: #b9b7b1 !important;
    padding-left: 1vw;
    padding-right: 1vw;
    margin-right: 1vw;
    margin-left: 1vw;
    font-weight: 500;
    font-size: 1rem;
    font-family: 'Sofia Pro', sans-serif;
  }

  /* For screens smaller than 1200px */
  @media only screen and (max-width: 1200px) {
    .nav .nav-item .nav-link {
      font-size: 1rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 800px */
  @media only screen and (max-width: 800px) {
    .nav .nav-item .nav-link {
      font-size: .9rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 600px */
  @media only screen and (max-width: 600px) {
    .nav .nav-item .nav-link {
      font-size: .7rem; /* Adjust as needed */
    }
  }

  /* For screens smaller than 460 */
  @media only screen and (max-width: 460px) {
    .nav .nav-item .nav-link {
      font-size: .7rem; /* Adjust as needed */
    }
  }


  
  .nav .nav-item .nav-link:hover {
    color: #EF8958 !important;
    font-family: 'Sofia Pro', sans-serif;
  }

  
/* "Get Started" button styles */

.nav .nav-item .get-started-btn {
    background: rgb(237,197,113);
    color: white !important;
    background: linear-gradient(90deg, rgba(237,197,113,1) 0%, rgba(213,95,35,1) 50%, rgba(237,197,113,1) 100%);
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-family: 'Sofia Pro', sans-serif;
    font-weight: 500;
    cursor: pointer;
    height: 40px;
  }
  
.nav .nav-item .get-started-btn:hover {
  filter: brightness(1.2);
  color: white !important;
}

.nav .nav-item .darkLight-btn {
  background: rgb(237,197,113);
  color: white !important;
  background: linear-gradient(90deg, rgba(237,197,113,1) 0%, rgba(213,95,35,1) 50%, rgba(237,197,113,1) 100%);
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-family: 'Sofia Pro', sans-serif;
  font-weight: 500;
  cursor: pointer;
  height: 40px;
}

  .nav .nav-item .darkLight-btn:hover {
  filter: brightness(1.2);
  color: white !important;
}

.logo-item {
    display: flex;
    align-items: center;
    justify-content: left;
  }
  
  .logo-name {
    width: 200px;
    height: 100%;
  }

  /* For screens smaller than 800px */
  @media only screen and (max-width: 800px) {
    .logo-name {
      width: 120px; /* Adjust as needed */
    }
  }

  /* For screens smaller than 600px */
  @media only screen and (max-width: 600px) {
    .logo-name {
      width: 100px; /* Adjust as needed */
    }
  }

  /* For screens smaller than 460px */
  @media only screen and (max-width: 460px) {
    .logo-name {
      width: 80px; /* Adjust as needed */
    }
  }

    /* For screens smaller than 460px */
    @media only screen and (max-width: 460px) {
      .nav .nav-item .nav-link {
        font-size: 2.25vw; /* Adjust as needed */
      }
    }